<template>
  <div class="index">
    <div class="logo">
      <img src="../assets/logo.png" alt="" />
    </div>
    <div class="user">
      <img :src="url + avatar" alt="" v-if="avatar" />
      <img src="../assets/header1.png" alt="" v-else />
      <div class="user-right">
        <p class="p1">{{ userName }}</p>
        <p class="p2">{{ deptName }}</p>
      </div>
      <div class="out">
        <el-dropdown @command="out" size="mini">
          <span class="el-dropdown-link">
            <i class="el-icon-caret-bottom"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="content">
      <div class="content-top">
        <div
          class="content-title"
          :class="active == 1 ? 'active' : ''"
          @click="change(1)"
        >
          待审批
        </div>
        <div class="total">{{ total1 }}</div>
        <div
          class="content-title"
          :class="active == 2 ? 'active' : ''"
          @click="change(2)"
        >
          已审批
        </div>
      </div>
      <div class="content-bottom" v-if="active == 1">
        <div v-if="total1 != 0">
          <div v-for="(item1, index1) in tableData1" :key="index1" class="page">
            <div
              v-for="(item2, index2) in item1"
              :key="index2"
              class="page-item"
            >
              <div class="subtitle">
                <div class="raduis"></div>
                <div>{{ gettype(item2.type) }}</div>
              </div>
              <div class="title">申请人：{{ item2.applyUserName }}</div>
              <div class="time" v-if="item2.createTime">
                {{ item2.createTime.slice(0,10) }}
              </div>
              <div class="content-con">
                <p>申请详情：{{ item2.title }}</p>
              </div>
              <div class="title1">待审批</div>
              <div
                class="goto"
                @click="goto(item2.id, item2.type, 0, item2.taskId)"
              >
                查看详情
              </div>
            </div>
          </div>
          <div class="loading" @click="gettask" v-if="show1">加载更多</div>
        </div>
        <div v-else class="noimg">
          <img src="../assets/no.png" alt="" width="60%" class="myimg" />
        </div>
      </div>
      <div class="content-bottom" v-else>
        <div v-if="total2 != 0">
          <div v-for="(item1, index1) in tableData2" :key="index1" class="page">
            <div
              v-for="(item2, index2) in item1"
              :key="index2"
              class="page-item"
            >
              <div class="subtitle">
                <div class="raduis"></div>
                <div>{{ gettype(item2.type) }}</div>
              </div>
              <div class="title">申请人：{{ item2.applyUserName }}</div>
              <div class="time">
                {{ item2.createTime.slice(0, 10) }}
              </div>
              <div class="content-con">
                <p>申请详情：{{ item2.title }}</p>
              </div>
              <div class="title1">已审批</div>
              <div
                class="goto"
                @click="goto(item2.id, item2.type, 1, item2.taskId)"
              >
                查看详情
              </div>
            </div>
          </div>
          <div class="loading" @click="gettaskdone" v-if="show2">加载更多</div>
        </div>
        <div v-else class="noimg">
          <img src="../assets/no.png" alt="" width="60%" class="myimg" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getInfo,
  taskList,
  taskDoneList,
  getapplytype,
  logout,
} from "../api/index";
import { removeToken } from "../auth";
export default {
  data() {
    return {
      userName: "",
      deptName: "",
      avatar: "",
      active: 1,
      queryParams1: {
        pageNum: 0,
        pageSize: 10,
      },
      queryParams2: {
        pageNum: 0,
        pageSize: 10,
      },
      tableData1: [],
      tableData2: [],
      total1: 0,
      total2: 0,
      typelist: [],
      show1: true,
      show2: true,
      url: "",
    };
  },
  created() {
    this.url = process.env.VUE_APP_BASE_API;
    getInfo().then((res) => {
      this.userName = res.user.userName;
      this.deptName = res.user.dept.deptName;
      this.avatar = res.user.avatar;
    });
    getapplytype().then((res) => {
      this.typelist = res.data;
    });
    this.gettask();
    this.gettaskdone();
  },
  watch: {
    tableData1() {
      let num = 0;
      for (let i = 0; i < this.tableData1.length; i++) {
        num = num + this.tableData1[i].length;
      }
      if (this.total1 == num) {
        this.show1 = false;
      }
    },
    tableData2() {
      let num = 0;
      for (let i = 0; i < this.tableData2.length; i++) {
        num = num + this.tableData2[i].length;
      }
      if (this.total2 == num) {
        this.show2 = false;
      }
    },
  },
  methods: {
    change(index) {
      this.active = index;
      this.tableData = [];
    },
    gettype(data) {
      let name;
      for (let i = 0; i < this.typelist.length; i++) {
        if (this.typelist[i].dictValue == data) {
          name = this.typelist[i]["dictLabel"];
          break;
        }
      }
      return name;
    },
    search() {
      if (this.active == 1) {
        this.gettask();
      } else {
        this.gettaskdone();
      }
    },
    gettask() {
      this.$set(this.queryParams1, "pageNum", this.queryParams1.pageNum + 1);
      taskList(this.queryParams1).then((res) => {
        let num = 0;
        let datalist = res.rows;
        for (let i = 0; i < datalist.length; i++) {
          if (datalist[i]["taskName"] == "重新提交") {
            this.$delete(datalist, i);
            num = num + 1;
          }
        }
        this.total1 = res.total - num;
        this.tableData1.push(datalist);
      });
    },
    gettaskdone() {
      this.$set(this.queryParams2, "pageNum", this.queryParams2.pageNum + 1);
      taskDoneList(this.queryParams2).then((res) => {
        this.total2 = res.total;
        this.tableData2.push(res.rows);
      });
    },
    goto(id, type, done, taskId) {
      this.$router.push(
        "/details/" +
          id +
          "/" +
          type +
          "/" +
          this.gettype(type) +
          "/" +
          done +
          "/" +
          taskId
      );
    },
    out() {
      logout().then((res) => {
        console.log(res);
        removeToken();
        this.$router.push("/login");
      });
    },
  },
};
</script>
<style scoped lang="scss">
.index {
  width: 100%;
  min-height: 100%;
  padding: 0 0.2rem;
  font-size: 0.16rem;
  background-color: rgba(239, 240, 242, 1);
  box-sizing: border-box;
  font-size: 0.16rem;
  .logo {
    width: 100%;
    padding-top: 0.3rem;
    img {
      width: 100%;
    }
  }
  .user {
    display: flex;
    margin-top: 0.2rem;
    justify-items: center;
    img {
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 0.1rem;
    }
    .user-right {
      margin-left: 0.2rem;
      .p1 {
        font-size: 0.18rem;
        margin-bottom: 0.03rem;
        font-weight: 900;
      }
      .p2 {
        font-size: 0.12rem;
      }
      // i {
      //   line-height: ;
      // }
    }
  }
  .search {
    display: flex;
    margin-top: 0.2rem;
    input {
      width: 90%;
      border-radius: 0.25rem;
      outline: none;
      text-indent: 0.2rem;
      border: 0;
      background: rgba(190, 195, 218, 0.5);
    }
    img {
      width: 10%;
    }
  }
  .content {
    width: 100%;
    margin-top: 0.2rem;
    .content-top {
      display: flex;
      align-items: center;
      border-bottom: 0.01px solid #979797;
      .content-title {
        color: rgba(34, 34, 34, 0.4);
        border-bottom: 0.04rem solid transparent;
        padding: 0.02rem 0;
        letter-spacing: 0.04rem;
        text-indent: 0.04rem;
        position: relative;
        bottom: -0.01rem;
      }
      .total {
        background-color: #fa5d5d;
        width: 0.2rem;
        line-height: 0.2rem;
        color: #fff;
        text-align: center;
        height: 0.2rem;
        border-radius: 50%;
        margin-right: 0.05rem;
      }
      .active {
        color: rgba(34, 34, 34, 1);
        border-bottom: 0.04rem solid #2c78ff;
        font-weight: 900;
      }
    }
    .content-bottom {
      margin-top: 0.3rem;
      padding-bottom: 0.3rem;
      .page {
        .page-item {
          background-color: #fff;
          padding: 0.2rem;
          margin-bottom: 0.4rem;
          border-radius: 0.15rem;
          .title {
            font-weight: 900;
          }
          .subtitle {
            font-weight: 700;
            width: 100%;
            font-size: 0.16rem;
            margin-bottom: 0.1rem;
            display: flex;
            align-items: center;
            overflow: hidden;
            .raduis {
              display: inline-block;
              width: 0.05rem;
              height: 0.05rem;
              border-radius: 50%;
              background-color: #e87373;
              margin-right: 0.05rem;
            }
          }
          .title {
            font-size: 0.14rem;
          }
          .time {
            font-size: 0.12rem;
            line-height: 0.2rem;
            color: #9a9a9a;
          }
          .content-con {
            font-size: 0.16rem;
            margin-top: 0.05rem;
            p {
              line-height: 0.2rem;
              font-size: 0.14rem;
              color: #9a9a9a;
            }
          }
          .title1 {
            color: #f58080;
            margin-top: 0.15rem;
          }
          .goto {
            margin-top: 0.3rem;
            padding-top: 0.2rem;
            border-top: 1px solid #ccc;
            text-align: center;
            line-height: 0.25rem;
            color: #388aff;
            font-weight: 900;
          }
        }
      }
      .loading {
        width: 100%;
        text-align: center;
        color: #2c78ff;
      }
      .noimg {
        width: 100%;
        .myimg {
          margin: 0 auto;
          padding-top: 0.4rem;
        }
      }
    }
  }
}
img {
  display: block;
}
</style>