import request from '../http.js'

// 查询业务流程列表
export function listBizProcess(query) {
    return request({
        url: '/oa/process/list',
        method: 'get',
        params: query
    })
}

// 查询业务流程详细
export function getBizProcess(id) {
    return request({
        url: '/oa/process/' + id,
        method: 'get'
    })
}

// 查询请假流程详细
export function showVerifyDialog(id) {
    return request({
      url: '/process/showVerifyDialog/' + id,
      method: 'get'
    })
  }
  
// 提交业务流程
export function submitApply(id) {
    return request({
        url: '/oa/process/submitApply/' + id,
        method: 'post'
    })
}

// 新增业务流程
export function addBizProcess(data) {
    return request({
        url: '/oa/process',
        method: 'post',
        data: data
    })
}

// 修改业务流程
export function updateBizProcess(data) {
    return request({
        url: '/oa/process',
        method: 'put',
        data: data
    })
}

// 删除业务流程
export function delBizProcess(id) {
    return request({
        url: '/oa/process/' + id,
        method: 'delete'
    })
}

// 查询业务流程待办列表
export function taskList(query) {
    return request({
        url: '/oa/process/taskList',
        method: 'get',
        params: query
    })
}// 查询业务流程已办列表
export function taskDoneList(query) {
    return request({
        url: '/oa/process/taskDoneList',
        method: 'get',
        params: query
    })
}
export function submitcontent(query) {
    return request({
        url: '/oa/process/submitcontent/' + query,
        method: 'get'
    })
}
export function selectUserList() {
    return request({
        url: '/oa/process/selectUserList',
        method: 'get'
    })
}
export function queryDeptList() {
    return request({
        url: '/oa/process/queryDeptList',
        method: 'get'
    })
}

// 完成待办
export function complete(data) {
    return request({
      url: '/process/complete',
      method: 'post',
      data: data
    })
  }

// 登录方法
export function login(username, password, code, uuid) {
    const data = {
        username,
        password,
        code,
        uuid
    }
    return request({
        url: '/login',
        method: 'post',
        data: data
    })
}

// 获取用户详细信息
export function getInfo() {
    return request({
        url: '/getInfo',
        method: 'get'
    })
}

// 退出方法
export function logout() {
    return request({
        url: '/logout',
        method: 'post'
    })
}

// 获取验证码
export function getCodeImg() {
    return request({
        url: '/captchaImage',
        method: 'get'
    })
}


// 获取申请类型
export function getapplytype() {
    return request({
        url: '/system/dict/data/type/biz_type',
        method: 'get'
    })
}



